import { template as template_4cc9740f0f68446cb1e79def9621cfea } from "@ember/template-compiler";
const SidebarSectionMessage = template_4cc9740f0f68446cb1e79def9621cfea(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
