import { template as template_1c89a4829b0b4930a61c7b703514e3d3 } from "@ember/template-compiler";
const FKLabel = template_1c89a4829b0b4930a61c7b703514e3d3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
